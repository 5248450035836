* {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Lexend', sans-serif;

}

/* .full-nav {
    background-color: #F7F7ED !important;
} */

/**Scrolling*/
html {
    scroll-behavior: smooth;
}

.logo {
    padding: 0rem calc((100vw - 1300px) / 3.5);
    margin-left: 20px;
}

.logo img {
    padding: 0 0rem;
    transition: 0.3s ease;
}

.logo img:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transition: 1s ease;
}

/*toggle*/
.navbar-toggler {
    padding: 0.25rem 0.75rem;
    margin-right: 20px;
}

.navbar-toggler:hover {
    border-color: #60946E;
    color: #60946E;

}

/*My Work Play About-->*/
.navbar-nav {
    padding: 0rem calc((100vw - 1300px) / 3.5);
    
}

nav a {
    text-decoration: none;
    color: #4D453E;
    padding: 0 1.5rem;
    margin-inline-end: 1rem;
    transition: color 0.3s ease, font-size 0.3s ease;}


nav a:hover {
    font-size: 1.13em;
    -webkit-transform: scale(1.00002);
    -ms-transform: scale(1.0002);
    transform: scale(1.000000001);
    transition: 1s ease;
}

.navBar {
    font-family: 'Lexend', sans-serif;
    border-bottom: 1.5px solid #4D453E;

}

.nav a {
    color: #4D453E;
}

.active {
    color: #60946E;
}

@media screen and (max-width: 720px) {
    .nav {
        flex-wrap: nowrap;

    }
}

@media screen and (max-width: 663px) {
    .nav {
        flex-wrap: nowrap;

    }
}