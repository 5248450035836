.overviewLeft .spotify a:hover {
    color: #195b32;
    font-weight: bold;

}

.spotify h1 {
    color: #195b32;
}

.spotify h2 {
    color: #195b32;
}

.spotify h3 {
    color: #247f44;
}

.spotify h4 {
    color: #2e994f;
}

.spotify h5 {
    color: #2e994f;
}

.spotify h6 {
    color: #2e994f;
    font-size: 16px;
    margin-left: 10px;
}

.spotify a:hover {
    color: #195b32;

}