.title-header {
    width: 50%;
    margin: 50px auto 50px;
}

.title-header h1 {
    margin-top: 5rem;
    font-size: 50px;
    color: #2D375D;

}

.title-header h2 {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 2.0rem;
}

.overview {
    width: 50%;
    margin: 50px auto 50px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 100px;
}
.overview a {
    color: var(--bs-body-color);
}
.overview h1 {
    font-size: 50px;
}

.overview h2 {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 5rem;

}

.overview h5 {
    font-size: 22px;
    margin-top: 1rem;
    color: #2D375D;

}

.overviewRight {
    margin-top: 0rem;
}

.summary {
    width: 50%;
    margin: 50px auto 50px;
    display: grid;
}

.summary a {
    color: var(--bs-body-color);
}

.summary h2 {
    margin-top: 2rem;
}

.leak h2 {
    color: #2D375D;
}

.summary h3 {
    margin-top: 1rem;
    font-size: 22px;
}

.leak h3 {
    color: #3F4F7A;
}

.summary h4 {
    margin-top: 1rem;
    font-size: 20px;
}

.leak h4 {
    color: #4C5F9B;
}


.summary h5 {
    font-size: 18px;

}

.leak h5 {
    color: #4C5F9B;
    font-size: 18px;
}

.summary p {
    margin-bottom: 10px;
}

.summary img {
    width: 100%;
}

.cover-image img {
    width: 100%;
}

summary ol {
    margin-left: 10px;
}

table {
    width: 100%;
    table-layout: fixed;
    overflow-x: auto;
    display: block;
  }
  