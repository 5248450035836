/**Youthful Finances*/
.overviewLeft .finances a:hover {
    color: #D32B6D;
    font-weight: bold;

}
.finances h1 {
    color: #D32B6D;
}

.finances h2 {
    color: #D32B6D;
}

.finances h3 {
    color: #FF4890;
}

.finances h4 {
    color: #FF5C9C;
}

.finances h5 {
    color: #F894BA;
}
.finances h6 {
    color: #F894BA;
    font-size: 16px;
    margin-left: 10px;
}


.finances a:hover {
    color: #D32B6D;

}