@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Kumbh Sans', sans-serif;
}



/** Individual Work Pages -----------------------------------------------*/
/* .individual {
    width: 60%;
    margin: 0px auto 50px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 80px;
}

.individual img {
    width: 100%;
    border-radius: 5px;
}

.individual h1 {
    font-size: 2.0rem;
    text-decoration: dotted;
}

.portfolio-section a {
    text-decoration: none;
}

.description {
    margin-top: 30%;

}

.individual p {
    font-size: 1.3rem;
}

.individual .Fusion-of-Cultures img {
    border-radius: 30px;

}

.backArrow {
    font-size: 4rem;
    width: 65%;
    margin: 0px auto 20px;
} */

/** Individual Work Pages Horizontal-----------------------------------------------*/
/* 
.backArrowHor {
    font-size: 4rem;
    width: 65%;
    margin: 0px auto 0px;
}

.titleOfPhoto h1 {
    font-size: 35px;
}

.individualHorizontal img {
    height: 500px;
}

.individualHorizontal {
    margin-top: 40px;
    display: flex;
    column-gap: 15px;
    align-items: center;
    justify-content: center;
    margin-left: 200px;
    margin-right: 200px;

}

.software {

    font-size: 1.3rem;
    margin-top: 40px;
    margin-bottom: 120px;
    align-items: center;
    justify-content: center;
} */