.lightboxPhoto img {
  width: 40%;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/**Fade*/
@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.fade1 {
  animation: fadeIn 2s ease-out forwards; 
}

.fade2 {
  animation: fadeIn 3s ease-out forwards; 
}
/**End of Fade*/

.slider {
  margin: 0 0px;
  overflow: "hidden";
  padding: 2rem 0;
}

.slider img {
  width: 100%;
}

.react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
}

.parent {
  margin: 30px;
}

.scrapbook {
  color: #4D453E;
  margin-top: 30px;
}

.sem3 {
  margin-top: -15px;
}

.sem1, .seattle {
  margin-top: -70px;
}



.tasaRetreat h5 {
  margin-top: 50px;
  margin-left: 30px;
  margin-bottom: -45px;

}
/**Scrapbook*/
.scrapbook img {
  align-items: center;
  justify-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  margin-bottom: 10px;
}

.scrapbook h1 {
  text-align: center;

}

.scrapbook h7 {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;

}

.scrapbook-text {
  margin-bottom: 20px;
}

.scrapbook-text h1 {
  margin-bottom: 10px;
}


.scrapbook h1 {
  font-size: 2.0rem;
  text-decoration: dotted;

}

.scrapbookIndividual {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 92.5%;
}

.scrapbookIndividual img {
  width: 160px;
  height: 160px;
}

.scrapbookIndividual .seattleScrapbook {
  display: flex;
}

.scrapbookIndividual .semester2Scrapbook {
  display: flex;
}

.scrapbookIndividual .semester1Scrapbook {
  display: flex;
}