.moodboarding {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping if images don't fit in one row */
  justify-content: center;
}

.moodboarding {
  text-align: center; /* Centers content inside the .moodboarding div */
}

.moodboarding img {
  width: calc(50% - 10px);
  max-width: 350px; 
  height: auto;
  display: inline-block;
  margin: 5px;
}

.flex-images {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  text-align: center;
}

.flex-images img {
  width: calc(50% - 10px);
  max-width: 100%;
  height: auto;
  display: inline-block;
}



.lavan h1 {
  color: #1C1A1A;
}

.lavan h2 {
  color: #1C1A1A;
}

.lavan h3 {
  color: #334E3D;
  font-size: 20px;
}

.lavan h4 {
  color: #334E3D;
  font-size: 16.5px;

}

.lavan h5 {
  color: #334E3D;
}



.lavan a:hover {
  color: #334E3D;
}

.table-first {
  font-weight: 15px;
  color: 334E3D;
}
