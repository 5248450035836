.yarl__container {
    background-color: rgba(0, 0, 0, 0.8) !important;
}

/**Fade*/
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade1 {
    animation: fadeIn 2s ease-out forwards;
}

/**End of Fade*/

.playHeading h1 {
    margin-top: 30px;
    font-size: 2rem;
    font-weight: 500;
    color: #4D453E;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
}


/**
shadow ------
*/

.images .artPiece {
    display: flex;
    /** hides overflow of text*/
    overflow: hidden;
    position: relative;
    list-style: none;
    margin-bottom: 15px;
    cursor: pointer;

}

.images .artPiece .title {
    position: absolute;
    bottom: -100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    transition: bottom 0.4s ease;
    background: linear-gradient(to top, black, transparent);
    color: white;
    border-radius: 5px;
    cursor: pointer;

}


.artPiece .title button {
    cursor: pointer;
    padding: 3px 5px;
    font-size: 1.1 rem;
    background: white;
}

.images .artPiece:hover .title {
    bottom: 0;
}

.image-container {
    cursor: pointer;
}

.image-container:hover {
    transform: scale(1.02);
    cursor: pointer;

}

/* .artPieceIndividual {
    max-width: 80%;
    max-height: 80%;
} */