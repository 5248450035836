/**Fade*/
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade1 {
    animation: fadeIn 2s ease-out forwards;
}

/**End of Fade*/
/** About page */
h1 {
    font-size: 2.0rem;
    font-weight: 500;
    color: #4D453E;
}

.aboutHeading {
    margin-top: 30px;

}

.about {
    width: 80%;
    margin: 50px auto 50px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 100px;
    color: #4D453E;

}

.about img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
    border-radius: 5px;
}


.about p {
    margin-top: 10px;
    font-size: 17px;
}

.about h3 {
    margin-top: 15px;
    font-size: 18px;
}

.moreAbout {
    width: 80%;
    margin: 50px auto 50px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 100px;
    color: #4D453E;

}

.skills {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
    border-radius: 5px;
}

a {
    color:
        #60946E;
}

a:hover {
    font-weight: 700;
}

.mail {
    margin-top: 10px;
}
.links a {
    font-style: normal;
    text-decoration: none;
}